<template>
  <div>
    <v-container>
      <v-card flat>
        <v-card-title id="sticky-nav" class="py-3 grey lighten-5">
          <v-row class="align-center"> 
            <v-col class="text-center" cols="2" sm="3">
              <v-btn text @click="toDashboard">
                <v-icon>mdi-arrow-left</v-icon>
                <!-- <span class="d-none d-sm-block custom-transform-class text-none">Back</span> -->
              </v-btn>
            </v-col>
            <v-col cols="8" sm="6"> 
              <div class="mx-auto">
                <p class="text-center my-0 font-weight-light headline">Branch SMS</p>
              </div>
            </v-col>
            <v-col class="text-center" cols="2" sm="3">
              <v-btn class="d-sm-none" @click="sendSmsDialog = true"  small color="success" fab>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn class="d-none d-sm-block" @click="sendSmsDialog = true" color="secondary" rounded>
                <v-icon left>mdi-plus</v-icon>
                <span class="custom-transform-class text-none">Compose SMS</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="sms.Data.length" class="px-0 pt-0 mb-0">
          <div class="spacer-div"></div>

          <v-card 
            :class="{'grey lighten-4': sms.IsRead}"
            class="mb-1 px-1" 
            @click="openSmsReplyDialog(sms)" 
            flat outlined tile 
            v-for="sms in sms.Data" :key="sms.OID"
          >
            <v-row>
              <v-col class="mb-1" cols="1" ></v-col>
              <v-col class="mb-1">
                <span class="body-1" :class="{'font-weight-bold': !sms.IsRead}">{{sms.Sender.Name}}</span>
                <span v-if="!sms.Sender.Name" class="body-1 font-weight-medium">{{sms.Sender.Number}}</span>
                <br>
                <span       
                  class="d-inline-block text-truncate"
                  style="max-width: 220px;"
                >{{sms.Message}}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-text class="mt-5" v-else>
          <span class="title mt-5">No msgs available</span>
        </v-card-text>
      </v-card>

      <!-- Reply to text Dialog -->
      <v-dialog
        v-model="smsReplyDialog"
        :fullscreen="$vuetify.breakpoint.xs" 
        max-width="500"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="blue">
            <!-- <v-icon color="white" @click="closeSmsReplyDialog">mdi-arrow-left</v-icon> -->
            <div class="d-flex flex-column ml-5">
              <span v-if="selectedSMS.Sender" class="white--text font-weight-light">{{selectedSMS.Sender.Name}}</span>
              <span v-if="selectedSMS.Sender" class="body-1 white--text font-weight-light">{{selectedSMS.Sender.Number}}</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="closeSmsReplyDialog" color="white" text>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text >
            <div id="textarea-wrapper" class="mt-5 d-flex flex-column">
              <!-- {{selectedSMS}} -->
              <div>
                <div class="speech-bubble">
                  <span>{{selectedSMS.Message}}</span>
                </div>
              </div>
              <div class="mt-auto">
                <v-textarea
                  v-model="smsReplytext"
                  counter
                  color="blue"
                  auto-grow rows="1"
                  outlined 
                  name="input-7-4"
                  label="Enter message"
                  append-icon="mdi-message-text"

                ></v-textarea>
                <!-- Select logged in user sms account -->
                <v-row>
                  <v-col class="mt-3 mb-n4" cols="9">
                    <v-select
                      v-if="this.$store.state.loggedInUserSmsAccounts"
                      v-model="selectedSmsAccount"
                      :background-color="confirmSelectBgColor"
                      color="blue"
                      :items="this.$store.state.loggedInUserSmsAccounts"
                      item-text="Number"
                      return-object
                      label="Select an account to reply"
                      outlined
                      dense
                      :menu-props="{ top: true, offsetY: true }"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-btn block :disabled="checkSmsAccount" @click="sendReply" color="blue" class="white--text">Send</v-btn>
                  </v-col>
                </v-row>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- SEND text Dialog -->
      <v-dialog
        v-model="sendSmsDialog"
        :fullscreen="$vuetify.breakpoint.xs" 
        max-width="500"
        transition="dialog-transition"
      >
        <SendSms
          @closeSendSmsDialog="closeSendSmsDialog"
          @confirmSmsSent="confirmSmsSent"
        />
      </v-dialog>

      <!-- Msg sent confirmation -->
      <Snackbar 
        :snackbar="showSnackbar"
        :snackbarText="snackbarText"
        :color="snackbarColor"
        @emitSnackbarTimeout="closeSnackbar"
      />
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Snackbar from '@/components/Snackbars/Snackbar.vue';
import SendSms from '@/components/MessageComponents/Slots/SendSms.vue';

export default {
  name: 'branch-messages',
  props: {
    dialog: Boolean,
  },
  components: {
    Snackbar,
    SendSms
  },
  data() {
    return {
      sendSmsDialog: false,
      smsReplyDialog: false,
      selectedSMS: '',
      smsReplytext: '',
      selectedSmsAccount: '',
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  methods: {
    toDashboard() {
      this.$router.go(-1)
    },
    openSmsReplyDialog(sms) {
      if(!sms.IsRead) {
        this.$store.dispatch('Messages/toggleMessageIsRead', sms.OID).then( () => {
          this.$store.dispatch('Messages/getBranchSms')
        })
      }
      console.log(sms)
      this.selectedSMS = sms
      this.smsReplyDialog = true
    },
    closeSmsReplyDialog() {
      this.smsReplyDialog = false
      this.clearFields()
    },
    sendReply() {
      this.$store.dispatch('Messages/sendSmsReply', {
        originalMessageID: this.selectedSMS.OID,
        textAccountID: this.selectedSmsAccount.OID,
        content: this.smsReplytext
      })
      .then(res => {
        console.log(res)
        this.smsReplyDialog = false

        this.showSnackbar = true
        this.snackbarText = 'Sms sent'
        this.snackbarColor = 'green'

        this.clearFields()
        this.$store.dispatch('Messages/getBranchSms')
      })
      .catch(err => {
        this.showSnackbar = true
        this.snackbarText = err
        this.snackbarColor = 'red'

        this.clearFields()
      })
    },
    clearFields() {
      this.selectedSMS = ''
      this.smsReplytext = ''
      this.selectedSmsAccount = ''
    },
    closeSnackbar() {
      this.showSnackbar = false
    },
    closeSendSmsDialog() {
      this.sendSmsDialog = false
    },
    confirmSmsSent() {
      // open a snackbar for confirmation
      this.showSnackbar = true,
      this.snackbarText = 'SMS sent successfully',
      this.snackbarColor = 'green'
    }
  },
  computed: {
    ...mapState({
      sms: state => state.branchSms
    }),
    checkSmsAccount() {
      if(this.selectedSmsAccount) {return false}
      return true
    },
    confirmSelectBgColor() {
      if(this.selectedSmsAccount) return ''
      return 'orange lighten-2'
    }
  }
}
</script>

<style scoped>
#textarea-wrapper {
  height: 75vh;
}

.spacer-div {
  height: 5px;
  width: 100vw;
  background: white;
}

.speech-bubble {
	position: relative;
	background: rgba(187, 222, 251, 0.562);
	border-radius: .4em;
  min-height: 50px;
  padding: 1rem;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 16px solid transparent;
	border-right-color:rgba(187, 222, 251, 0.562);
	border-left: 0;
	border-bottom: 0;
	margin-top: -3px;
	margin-left: -16px;
}
</style>
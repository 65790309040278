<template>
  <SmsMessages />
</template>

<script>
import SmsMessages from '@/components/MessageComponents/SmsMessages.vue';

export default {
  name: 'branch-sms-view',
  components: {
    SmsMessages
  }
  ,
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>
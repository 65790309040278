<template>
  <v-card>
    <v-card-title class="secondary px-0">
      <!-- <v-icon color="white" >mdi-arrow-left</v-icon> -->
      <div class="d-flex flex-column ml-5">
        <span class="white--text font-weight-light">Send New SMS</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="closeSendSmsDialog" color="white" text>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div id="textarea-wrapper" class="mt-5 d-flex flex-column">
        <div>
          <v-combobox
            color="blue"
            v-model="toNumbers"
            label="Enter recipients"
            multiple
            chips
            append-icon="mdi-cellphone-text"

          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                @click:close="data.parent.selectItem(data.item)"
                :color="validateNumberColor(data.item)"
                
              >
                {{ data.item }}
                <v-icon small right @click="data.parent.selectItem(data.item)">mdi-close</v-icon>
              </v-chip>
            </template>
          </v-combobox>
          <v-alert 
            v-model="alert"
            type="error"
            color="red"
            dismissible
          >
            Please add a valid number.
          </v-alert>
        </div>
        <!-- <div v-if="bubbleContent.length">
          <div v-for="(text, i) in bubbleContent" :key="i" class="speech-bubble">
            <span> {{ text }} </span>
          </div>
        </div> -->
        <div class="mt-12 mb-n10">
          <v-textarea
            v-model="content"
            counter
            color="blue"
            rows="8"
            outlined 
            name="input-7-4"
            label="Enter message"
            append-icon="mdi-message-text"
          ></v-textarea>
          <!-- Select logged in user sms account -->
          <v-row>
            <v-col class="mt-1 mb-n6" cols="9">
              <v-select
                v-if="this.$store.state.loggedInUserSmsAccounts"
                v-model="selectedSmsAccount"
                :background-color="confirmSelectBgColor"
                color="blue"
                :items="this.$store.state.loggedInUserSmsAccounts"
                item-text="Number"
                return-object
                label="Select number to send from"
                outlined
                dense
                :menu-props="{ top: true, offsetY: true }"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn :loading="loading" block :disabled="validateAllNumbers" @click="sendSms" color="blue" class="white--text">Send</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <!-- Error snackbar -->
    <v-snackbar
      absolute
      top
      v-model="errorSnackbar"
      multi-line
      color="red"
      class="mt-12"
    >
      <span class="white--text">Error sending text msg. <br> Please try again.</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="piwhitenk"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'send-sms',
  data() {
    return {
      alert: false,
      content: '',
      bubbleContent: [],
      selectedSmsAccount: this.$store.state.loggedInUserSmsAccounts[0],
      msgSent: false,
      toNumbers: [],
      loading: false,
      errorSnackbar: false
    }
  },
  computed: {
    validateAllNumbers() {
      if(this.toNumbers.every(this.numberValidator)) {
        return false
      } else {
        return true
      }
    },
    checkSmsAccount() {
      if(this.selectedSmsAccount) {return false}
      return true
    },
    confirmSelectBgColor() {
      if(this.selectedSmsAccount) return ''
      return 'orange lighten-2'
    },
  },
  methods: {
    numberValidator(number) {
      const pattern = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g
      return pattern.test(number)
    },
    validateNumberColor(number) {
      const pattern = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g
      if(pattern.test(number)) { 
        return 'grey lighten-2'
      }
        return 'red'
    },
    sendSms() {
      if(!this.toNumbers.length) {
        this.alert = true
      } else {
        this.loading = true
        this.$store.dispatch('Messages/sendSms', {
          textAccountID: this.selectedSmsAccount.OID,
          content: this.content,
          to: this.toNumbers
        })
        .then( () => {
          console.log('SMS sent successfully!')
          // this.bubbleContent.push(this.content)
          this.closeSendSmsDialog()
          this.$emit('confirmSmsSent')
          // this.msgSent = true
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.errorSnackbar = true
        })
      }
    },
    closeSendSmsDialog() {
      this.loading = false
      this.content = ''
      this.bubbleContent = []
      this.msgSent = false
      this.toNumbers = []
      this.$emit('closeSendSmsDialog')
    }
  },
  watch: {
    toNumbers(val) {
      if(val.length >= 1) {
        this.alert = false
      }
    }
  }
}
</script>

<style>
#textarea-wrapper {
  height: 75vh;
}
.speech-bubble {
	position: relative;
	background: rgba(187, 222, 251, 0.562);
	border-radius: .4em;
  min-height: 50px;
  padding: 1rem;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 16px solid transparent;
	border-right-color:rgba(187, 222, 251, 0.562);
	border-left: 0;
	border-bottom: 0;
	margin-top: -3px;
	margin-left: -16px;
}
</style>